label {
    font-family: $font-family-medium;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  /* For Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  /* Webkit browsers like Safari and Chrome */
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .form-field {
    margin-bottom: 15px;

    @include media-breakpoint-down(lg) {
      margin-bottom: 10px;
    }
  }

  // input type text / password
  .select {
    font-family: $font-family-bold;
    box-sizing: border-box;
    width: auto;
    min-width: 200px;
    height: 46px;
    padding: 0 35px 0 10px;
    border-radius: 3px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    outline: 0;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0.17);
    border: 1px solid #E8E7EC;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* Some browsers will not display the caret when using calc, so we put the fallback first */
    background: url('/assets/img/arrow-down.svg') rgba(255, 255, 255, 0.17) no-repeat 98.5% !important; /* !important used for overriding all other customisations */
    background: url('/assets/img/arrow-down.svg') rgba(255, 255, 255, 0.17) no-repeat calc(100% - 15px) !important; /* Better placement regardless of input width */

    color: $color-white;
    cursor: pointer;

    @media all and (-ms-high-contrast: none) {
      padding-right: 0; /* IE10 */

      *::-ms-backdrop,
      body {
        padding-right: 0; /* IE11 */
      }
    }

    &:disabled {
      opacity: .5;
      color: rgba($color-black, .28);
      border-color: $color-white;
    }
  }

  .form-field {
    // general wrapper for form-field elements
    &__wrapper {
      margin-bottom: 20px;

      &.has-error {
        label {
          color: $color-error;
        }

        input {
          color: $color-error;
          border-color: $color-error;
        }
      }

      &--select{
        position: relative;
      }
    }

    // general label-styling in form-fields
    &__label {
      font-family: $font-family-medium;
      font-size: 14px;
      font-weight: bold;
      display: inline-block;
      color: $color-white;

      &.has-error {
        color: $color-error;
      }
    }
  }
