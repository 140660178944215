/**
 * Typography
 */

/**
 * Typography
 */

 // Font Family
 $font-family-regular: "Metropolis Regular", Arial, sans-serif, !default;
 $font-family-medium: "Metropolis Medium", Arial, sans-serif, !default;
 $font-family-bold: "Metropolis Bold", Arial, sans-serif, !default;
 $font-family-black: "Metropolis Black", Arial, sans-serif, !default;
 $font-family-headings: $font-family-black;

 // Font Weight
 $font-weight-light: 300 !default;
 $font-weight-regular: 400 !default;
 $font-weight-medium: 600 !default;
 $font-weight-bold: 700 !default;
 $font-weight-black: 800 !default;

 // Line Height
 $line-height-none: 1 !default;
 $line-height-base: 24px;


// Line Height
$line-height-none: 1 !default;
$line-height-base: 24px;

// Headings Responsive Size
$h1-font-size: ( xs: 20px, sm: 22px, md: 24px, lg: 26px, xl: 28px, xxl: 32px );
$h2-font-size: ( xs: 22px, sm: 24px, md: 26px, lg: 28px, xl: 32px, xxl: 36px );
$h3-font-size: ( xs: 20px, sm: 22px, md: 24px, lg: 26px, xl: 28px, xxl: 32px );


$headings-font-family: $font-family-regular !default;
$headings-font-weight: $font-weight-bold !default;
$headings-letter-spacing: normal !default;
$headings-line-height: 1.2 !default;
$headings-color: inherit !default;
$headings-text-transform: inherit !default;