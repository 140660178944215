footer {
    padding: 20px 15px;
    font-family: $font-family-bold;
    color: rgba($color-white, .75);
    font-size: 12px;

    a {
        color: rgba($color-white, .75);
        text-decoration: underline;

        display: block;
        text-align: center;
    }
}