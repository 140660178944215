.header {
    margin: 0 auto 75px;
    text-align: center;

    @include media-breakpoint-down(md) {
        margin: 0 auto 50px;
    }

    &__logo {
        width: 100%;
        max-width: 600px;
        margin: 0 0 15px;

        @include media-breakpoint-down(md) {
           padding: 0 20px;
        }
    }

    p {
        font-family:  $font-family-headings;
    }
}