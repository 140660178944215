@font-face {
    font-family: 'Metropolis Regular';
    font-style: normal;
    src: url('../assets/fonts/Metropolis-Regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../assets/fonts/Metropolis-Regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  @font-face {
    font-family: 'Metropolis Medium';
    font-style: normal;
    src: url('../assets/fonts/Metropolis-Medium.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../assets/fonts/Metropolis-Medium.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  @font-face {
    font-family: 'Metropolis Bold';
    font-style: normal;
    src: url('../assets/fonts/Metropolis-Bold.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../assets/fonts/Metropolis-Bold.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  @font-face {
    font-family: 'Metropolis Black';
    font-style: normal;
    src: url('../assets/fonts/Metropolis-Black.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../assets/fonts/Metropolis-Black.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }