.main {
    width: 100%;
    margin-top: 50px;

    @include media-breakpoint-down(md) {
        margin-top: 0;
    }

    nav {
        display: flex;
        align-items: center;
        justify-content: center;

        margin: 20px 0;
        width: 100%;

        @include media-breakpoint-down(sm) {
            display: inline-block;
            margin: 0;
            text-align: center;
        }

        a {
            margin: 0 25px;
            text-align: center;

            @include media-breakpoint-down(sm) {
                display: inline-block;
                align-items: center;
                justify-content: center;
                margin: 0 10px 15px;
            }

            img {
                opacity: .25;
                text-align: center;
            }

            &:hover {
                img {
                    @include transition;
                    opacity: 1;
                }
            }

            img {
                max-width: 180px;

                @include media-breakpoint-down(sm) {
                    max-width: 90px;
                }
            }
        }
    }
}

.cms {
    h1 {
        margin-bottom: 20px;
    }

    p {
        margin-bottom: 20px;
    }

    a {
        color: $color-white;
    }
}