/**
 * Document defaults (html, body)
 */

 * {
    outline: 0;
    border: 0;
    box-sizing: border-box;
}

html,
body {
    width: 100%;
}


html {
    font-family: $font-family-regular;
    font-size: 16px;
    color: $color-white;
    line-height: $line-height-base;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;

    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

html::-webkit-scrollbar {
    width: 0px; /* For Chrome, Safari, and Opera */
}

body {
    background: #000;
    height: 100vh;

    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    flex-direction: column;

    @include media-breakpoint-down(sm) {
        height: 100%;
    }

    &.cms {
        height: 100%;

        header {
            margin-top: 100px;
        }
    }
}
.home {
    @include media-breakpoint-down(xs) {
        overflow: hidden;
        height: 100vh;
    }
}